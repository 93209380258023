if (!window.fetch) {
    import('whatwg-fetch');
}

import LazyLoad from 'vanilla-lazyload';
import advancedSearch from './modules/advanced-search';
import amountHandler from './modules/amount-handler';
import availabilityPopup from './modules/availability-popup';
import banners from './modules/banners';
import buttonEvents from './modules/button-events';
import consentBar from './modules/general/consent-bar';
import { readConsentCookie } from './modules/general/consent-manager';
import rwd from './modules/general/rwd';
import widgetFacebook from './modules/general/widget-facebook';
import newsletterPopup from './modules/newsletter-popup';
import newsletterCookieModal from './modules/newsletter-cookie-modal';
import pjaxCode from './modules/pjax-code';
import polls from './modules/polls';
import priceFiltering from './modules/price-filtering';
import productAdder from './modules/product-adder';
import bannersProductCard from './modules/product-detail/banners-product-card';
import comments from './modules/general/comments';
import recaptchaInit from './modules/recaptcha';
//import installWorker from './modules/utils/install-worker';
import searchEngine from './modules/search-engine';
import dropdownPicker from './modules/dropdown-picker';
import tabs from './modules/tabs';
import treeboxCount from './modules/treebox-count';
import eventReceiver from './modules/utils/event-receiver';
import wishlist from './modules/wishlist';
import calculateFreeShipment from './modules/general/calculate-free-shipment';
import questionForm from './modules/question-form';
import showcase from './modules/general/showcase';
import initObserver from './modules/general/observer';
import filterNarrowDown from './modules/filter-narrow-down';

window.initConsent = readConsentCookie;


if ('IntersectionObserver' in window && document.querySelectorAll('[data-run-fancybox]').length > 0) {
    import('@fancyapps/ui/dist/fancybox/fancybox.umd').then(module => {
        module.Fancybox.bind('[data-run-fancybox=""]', {
            autoFocus: false,
            trapFocus: false,
            placeFocusBack: false,
            groupAll: true,
            hideScrollbar: false
        });

        module.Fancybox.bind('[data-run-fancybox="single"]', {
            autoFocus: false,
            trapFocus: false,
            placeFocusBack: false,
            groupAll: false,
            hideScrollbar: false
        });
    });
}

//available types of body: cart, listing, detail, mainpage, advanced-search
const typeOf = document.body.getAttribute('data-type');

switch (typeOf) {
    case 'cart':
        import('./modules/cart/_index.js').then((module) => {
            module.cartDeliveryPoints();
            module.cartCommands();
            module.cartManagement();
            module.cartStepBubbles();
        });
        break;
    case 'listing':
        pjaxCode();
        priceFiltering();
        filterNarrowDown();
        break;
    case 'detail':
        import('./modules/product-detail/option-chooser').then((module) => { module.default() });
        amountHandler();
        bannersProductCard();
        availabilityPopup();
        break;
    case 'mainpage':
        break;
    case 'advanced-search':
        advancedSearch();
        break;
    default:
        break;
}

recaptchaInit();
comments();
banners();
buttonEvents();
consentBar();
polls();
rwd();
treeboxCount();
widgetFacebook();
wishlist();
dropdownPicker();
newsletterPopup();
newsletterCookieModal();
eventReceiver();
productAdder();
tabs();
searchEngine();
//installWorker();
calculateFreeShipment();
questionForm();
showcase();

// HEADER OBERVER
const header = document.querySelector('.shop-header');
const headerSentinel = document.querySelector('.header-sentinel');

if (header) {
    initObserver(headerSentinel, function () {
        header.classList.add('stuck');
    }, function () {
        header.classList.remove('stuck');
    });
}

// VALIDATIONS
const toValidateList = document.querySelectorAll('[data-run-validator]');

if (toValidateList.length > 0) {
    import('./modules/front-validation').then(module => module.default(toValidateList));
}

// LAZY LOAD IMAGES
window.lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazyload',
    unobserve_entered: true
});

// SCROLLING OFFSET
window.scrollOffset = 134;

// INIT TOOLTIPS
const tippyNeeded = document.querySelectorAll('[data-tippy-content]').length > 0;

if (tippyNeeded) {
    import('tippy.js').then((module) => {
        module.delegate('body', {
            target: '[data-tippy-content]',
            allowHTML: true
        });
    });
}

// LINKIFY CHAT
const threadMessages = document.querySelector('.thread-messages');

if (threadMessages) {
    import('linkify-html').then((module) => {
        const messages = threadMessages.querySelectorAll('.message-body');

        messages.forEach(function (message) {
            const text = message.textContent;
            const linkifiedText = module.default(text, { target: "_blank" });

            message.innerHTML = linkifiedText;
        });
    });
}

// INP TESTING
//let worstInp = 0;

//const observer = new PerformanceObserver((list, obs, options) => {
//    for (let entry of list.getEntries()) {
//        if (!entry.interactionId) continue;

//        entry.renderTime = entry.startTime + entry.duration;
//        worstInp = Math.max(entry.duration, worstInp);

//        //console.clear();
//        console.log('[Interaction]', entry.duration, `type: ${entry.name} interactionCount: ${performance.interactionCount}, worstInp: ${worstInp}`, entry, options);

//    }
//});

//observer.observe({
//    type: 'event',
//    durationThreshold: 0,
//    buffered: true
//});
